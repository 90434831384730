<template>
    <div class="breadcumbwrapper-container">
        <BreadCumb :name="name" :back="back"/>
        <component style="padding-top: 75px;" :is="component" :user="user" v-bind="params"/>
    </div>
</template>
<script>
import BreadCumb from './BreadCumb.vue';
export default {
  name: 'breadCumbWrapper',
  props: {
    component: Object,
    name: String,
    back: Function,
    user: Object,
    params: Object
  },
  components: {
    BreadCumb
  }
}
</script>
<style>
.breadcumbwrapper-container {
  height: 100%;
}
@media screen and (max-width: 900px) {
  .breadcumbwrapper-container {
    padding-left: 100px;
  }
}
</style>
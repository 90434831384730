<template>
<vs-card >
    <template #title>
        <h3>{{ article != null ? article.title : '..' }}</h3>
    </template>
    <template #img>
        <img :src="baseUri + (user != null && user.is_admin ? '/backoffice' : '') + '/blog/articles/' + article.id + '/pictures/' + article.picture_id + '?type=MAIN'" alt="">
    </template>
    <template #text>
        <p>
            {{ article != null ? (new Date(article.updated_at)).toLocaleDateString() : '..' }}
        </p>
    </template>
    <template #interactions v-if="article != null">
        <vs-button style="color: white!important;" shadow dark v-for="tag in article.tags" :key="'tag-' + tag">
            #{{ tag }}
        </vs-button>
    </template>
</vs-card>
</template>



<script>
import { baseUri } from '../utils/ApiMiddleware';
export default {
    props: {
        article: Object,
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            baseUri
        }
    }
}
</script>

<style>

</style>